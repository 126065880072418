import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { BlockRenderer, Grid, Margin } from '@components/atoms'
import { GridItem, GridRule } from '@components/molecules'
import { TransitionMask, Filters } from '@components/organisms'
import { PageProps } from '@types'
import { getCdnPath } from '@utils'
import { Helmet } from 'react-helmet'


const PressPage = ({data: { 
  sanityPage: {
    gridItems,
    gridSnap,
    inverted,
    shareImage,
    description,
    title,
    _rawIntroduction,
  }
}}: PageProps) => {
  const parsedTitle = `TWA: ${title}`
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  if(shareImage) {
    meta.push({ property: "og:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
    meta.push({ name: "twitter:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) })
  }

  const sectors = ['Featured']
  const [activeSector, setActiveSector] = React.useState<string>()
  const filteredGridItems = gridItems.filter(i => !activeSector || activeSector == 'Featured' && i.featured)

  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>

      <StyledMargin>
        <Grid {...{ gridSnap }} >
          <Title>
            <BlockRenderer>{_rawIntroduction}</BlockRenderer>
          </Title>
          <Filters {...{ sectors, activeSector, setActiveSector }} />
          {filteredGridItems.filter(p => !!p).reverse().map((item) => (
            <GridItem key={item._key} {...item} />
          ))}
          <GridRule ruleType="gap" columnStart={1} columns={12} mobileColumnStart={1} mobileColumns={6} gapHeight={5} _key="bottom_gap" />
        </Grid>
      </StyledMargin>
            
      <TransitionMask {...{ inverted }} />
    </>
  )
}


const Title = styled.div`
  @media only screen and (min-width: 744px) {
    grid-column-start: 3;
    grid-column-end: span 10;
  }
`


const StyledMargin = styled(props => <Margin {...props} />)`
  padding: 60vw 0 0 0;
  @media only screen and (min-width: 744px) {
    padding: 20vw 0 0 0;
  }
`


export const query = graphql`
  query PressPageQuery {
    sanityPage(slug: {current: {eq: "press"}}) {
      ...pageFields
    }
  }
`

export default PressPage
